
import ApiService from "@/core/services/ApiService"
import { computed, defineComponent, onMounted, ref } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import { SelectModel } from "@/core/models/SelectModel"
import { Transfer } from "@/core/models/Transfer"

import { FormInstance } from "element-plus"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { CashBookListItem } from "@/core/models/CashBookListItem"
import swalConfirm from "@/core/helpers/swal-confirm"
import ElInputCurrency from "@/components/input/ElInputCurrency.vue"
import { currencyForm } from "@/core/helpers/currencyformat"

export default defineComponent({
  name: "account-virman",
  components: { ElInputCurrency },
  setup() {
    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)
    const isCashBookSelectLoading = ref(false)

    const businessList = ref<SelectModel[]>([])
    const cashBookList = ref<CashBookListItem[]>([])

    const transferData = ref<Transfer>({} as Transfer)

    const activeBusinessId = ref<string | undefined>()
    const ruleFormRef = ref<FormInstance>()

    const getToCashbookAccountBalance = computed(() => {
      const cashBookAccount = cashBookList.value.find(x => x.id == transferData.value.toCashBookId)
      return cashBookAccount?.balance
    })

    const getFromCashbookAccountBalance = computed(() => {
      const cashBookAccount = cashBookList.value.find(
        x => x.id == transferData.value.fromCashBookId
      )
      return cashBookAccount?.balance
    })

    const rules = ref({
      fromCashBookId: getRule(RuleTypes.SELECT, "Hesap"),
      toCashBookId: getRule(RuleTypes.SELECT, "Hesap"),
      amount: getRule(RuleTypes.NUMBER, "Tutar"),
      description: getRule(RuleTypes.TEXT100, "Açıklama"),
    })

    onMounted(async () => {
      await getBusinessList()
    })

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
      isBusinessSelectLoading.value = false
    }

    const getCashBookList = async businessId => {
      isCashBookSelectLoading.value = true
      const { data } = await ApiService.get("cash-book/business/" + businessId)
      isCashBookSelectLoading.value = false
      cashBookList.value = data
      if (cashBookList.value.length == 1) {
        transferData.value.fromCashBookId = cashBookList.value[0].id
      }
    }

    function addTransfer(formEl: FormInstance | undefined) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const confirmResult = await swalConfirm()
          if (confirmResult.isConfirmed) {
            const data = {
              ...transferData.value,
            }
            await ApiService.post("cash-book/transfer", data)
            activeBusinessId.value = undefined
            formEl.resetFields()
          }
        }
      })
    }

    const OnChangeBusiness = async businessId => {
      transferData.value.fromCashBookId = undefined
      transferData.value.toCashBookId = undefined
      await getCashBookList(businessId)
    }

    return {
      dateForm,
      DateFormatTypes,
      isTableDataLoading,
      cashBookList,
      businessList,
      activeBusinessId,
      Edit,
      getCashBookList,
      isBusinessSelectLoading,
      isCashBookSelectLoading,
      OnChangeBusiness,
      ruleFormRef,
      rules,
      transferData,
      addTransfer,
      currencyForm,
      getToCashbookAccountBalance,
      getFromCashbookAccountBalance,
    }
  },
})
